<template>
  <div class="container">
    <ul class="progressbar">
      <li class="active" v-for="i in progress - 1" :key="i">{{ i * 20 }}%</li>

      <!-- <img
        src="/assets/icon/trophy.svg"
        width="50"
        style="position: absolute;top: -36px;left: {{(70*(progress-1))+4}}px;"
      /> -->
      <li class="active" :key="progress">{{ progress * 20 }}%</li>

      <img
        src="/assets/icon/trophy.svg"
        width="35"
        style="position: absolute; top: -21px;right: 34px"
      />
      <li v-for="j in 5 - progress" :key="j">{{ (progress + j) * 20 }}%</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "stepProgressSlider",
  props: ["progress"],
  data() {
    return {};
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  position: absolute;
  z-index: 1;
  margin-top: 20px;
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
}

.progressbar {
  counter-reset: step;
  padding-left: 5px;
}

.progressbar li {
  float: left;
  width: 14.28%;
  position: relative;
  text-align: center;
  margin-right: 19px;
  color: #e4e4e4;
  font-weight: bold;
  line-height: 9px;
}

.progressbar li.active {
  color: var(--ion-color-primary);
}

.progressbar li:before {
  content: "";
  counter-increment: step;
  width: 20px;
  height: 20px;
  border: 3px solid var(--ion-color-medium-shade);
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background: var(--ion-color-medium);
}

.progressbar li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background: var(--ion-color-medium-shade);
  top: 12px;
  left: -67%;
  z-index: -1;
}

.progressbar li.active:before {
  border-color: var(--ion-color-primary);
  background: var(--ion-color-secondary);
}

.progressbar li.active:after {
  background: var(--ion-color-secondary);
}

.progressbar li.active li:after {
  background: var(--ion-color-secondary);
}

.progressbar li.active li:before {
  border-color: var(--ion-color-primary);
  background: var(--ion-color-secondary);
}

.progressbar li:first-child:after {
  content: none;
}
</style>