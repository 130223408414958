<template>
  <ion-page>
    <ion-content
      style="--background: var(--ion-color-light); align-items: center"
    >
      <radial-progress-bar
        :diameter="diameter"
        :completed-steps="completedSteps"
        :total-steps="totalSteps"
        :start-color="startColor"
        :stop-color="stopColor"
        :inner-stroke-color="innerStrokeColor"
      >
        <div id="circle">
          <span style="text-align: center; width: 100%">{{ initials }}</span>
        </div>
      </radial-progress-bar>

      <ion-card class="main-card" style="padding-top: 104px">
        <ion-card-header>
          <ion-card-title
            >{{ user.first_name }} {{ user.last_name }}</ion-card-title
          >
          <p style="margin-bottom: 0px">
            Welcome back, {{ user.first_name }}. {{ waitingRoom }}
          </p>
          <p class="red-sub-title">
            Complete your profile. It's at {{ progress }}%
          </p>
        </ion-card-header>

        <ion-card-content>
          <div class="step-progress">
            <step-progress :progress="progressStep"></step-progress>
          </div>

          <ion-card
            v-for="notification in notifications"
            :key="notification"
            class="notification-cards"
          >
            <ion-card-header>
              <ion-card-title style="color: black">{{
                notification.title
              }}</ion-card-title>
              <ion-card-content>
                {{ notification.description }}
                <br />
                <a v-if="notification.link == '#'"
                  class="notifications-links"
                  href="#"
                  @click.prevent="cancelConsult()"
                  >{{ notification.link_title }}</a
                >
                <router-link v-else
                  class="notifications-links"
                  :to="{ path: notification.link }"
                  >{{ notification.link_title }}</router-link
                >
                <a
                  class="notifications-links"
                  href="#"
                  @click.prevent="dismiss(notification.id)"
                  >DISMISS</a
                >
              </ion-card-content>
            </ion-card-header>
          </ion-card>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonPage,
} from "@ionic/vue";
import { defineComponent } from "vue";
import RadialProgressBar from "vue-radial-progress";
import stepProgress from "@/components/patient/stepProgressSlider.vue";

export default defineComponent({
  name: "WeDoc",
  components: {
    IonPage,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonContent,
    RadialProgressBar,
    stepProgress,
  },
  data() {
    return {
      payload: {
        id: null,
        selected: null,
        user_id: null,
      },
      completedSteps: 20,
      totalSteps: 100,
      diameter: 178,
      strokeLinecap: "round",
      startColor: "#BF0000",
      stopColor: "#BF0000",
      innerStrokeColor: "#D1D1D1",
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    initials() {
      return this.user.first_name.charAt(0) + this.user.last_name.charAt(0);
    },
    progressStep() {
      return 1;
    },
    progress() {
      return 20;
    },
    notifications() {
      return this.$store.state.notifications.notifications;
    },
  },
  async ionViewDidEnter() {
    this.payload.user_id = this.user.id;
    this.$store.dispatch("PreviousConsults", this.user.id).then();
    this.$store.dispatch("Notifications", this.user.id).then();
    this.$store.dispatch("TradingHours");
  },
  methods: {
    async dismiss(id) {
      this.payload.id = id;

      await this.$store.dispatch("DismissNotifications", this.payload).then(
        res => {
            console.log(res);
            this.$store.dispatch("Notifications", this.user.id).then();
        }
        ).catch(
          error => {
          console.log(error.error);
          }
      );
    },
    async cancelConsult() {
      await this.$store.dispatch("CancelConsult", this.user.id).then(
        res => {
            console.log(res);
            this.$store.dispatch("Notifications", this.user.id).then();
        }
        ).catch(
          error => {
          console.log(error.error);
          }
      );
    },
  },
});
</script>

<style lang="css" scoped>
#circle {
  width: 140px;
  height: 140px;
  background: var(--ion-color-tertiary);
  border-radius: 50%;
  color: var(--ion-color-primary);
  display: flex;
  align-items: center;
  font-size: 50px;
}

.radial-progress-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
  top: 73px;
}

.radial-progress-inner {
  width: 125px;
  height: 150px;
}

.step-progress {
  min-height: 97px;
}
</style>
